/* GÖRGETÉS ELEMHEZ */
function scrollToId(i, d, s, o) {
    if (d == undefined) {
        var d = 100
    }
    if (s == undefined) {
        var s = 1000
    }
    if (o == undefined) {
        var o = -10
    }
    try {
        $("html, body").delay(d).animate({scrollTop: $("#" + i).offset().top + o}, s)
    } catch (err) {
    }
};