function showGoTop() {
    if ($(this).scrollTop() > 200) {
        $("#go-top").fadeIn(200)
    } else {
        $("#go-top").fadeOut(200)
    }
}
$(window).scroll(function () {
    showGoTop();
});
$(function () {
    $('body').append('<div id="go-top"></div>');
    showGoTop();
    $("#go-top").click(function (a) {
        a.preventDefault();
        $("html, body").animate({scrollTop: 0}, 300);
    });
});