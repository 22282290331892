function changeTopNav() {
    if ($(this).scrollTop() > 0) {
         $('body').addClass('scrolled');
    } else {
        $('body').removeClass('scrolled');
    }
}

$(window).scroll(function () {
    changeTopNav();
});

$(function () {
    changeTopNav();
});