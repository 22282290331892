/* INPUT SZŰRÉS CSAK BETŰ, SZÁM, STB */
var telNumber = /[1234567890\/\(\)\+\-\s]/g;
var digitsOnly = /[1234567890]/g;
var integerOnly = /[0-9\.]/g;
var floatOnly = /[0-9\.\,]/g;
var alphaOnly = /[A-Za-z]/g;
var usernameOnly = /[0-9A-Za-z\._-]/g;
function restrictInput(h, i, j, g) {
    if (!i) {
        var i = window.event
    }
    if (i.keyCode) {
        code = i.keyCode
    } else {
        if (i.which) {
            code = i.which
        }
    }
    var e = String.fromCharCode(code);
    if (code == 27) {
        this.blur();
        return false
    }
    if (!i.ctrlKey && code != 9 && code != 8 && code != 36 && code != 37 && code != 38 && (code != 39 || (code == 39 && e == "'")) && code != 40) {
        if (e.match(j)) {
            if (g == "checkdot") {
                if (((code != 46 && code != 44) || ($(h).val().indexOf(".") != -1 || $(h).val().indexOf(",") != -1)) && (code < 48 || code > 57)) {
                    return !isNaN(h.value.toString() + e)
                } else {
                    return true
                }
            } else {
                return true
            }
        } else {
            return false
        }
    }
}
function replaceComa(a) {
    var b = $(a).val();
    b = b.replace(/,/g, ".");
    $(a).val(b)
};function replaceDOT(b) {
    var a = $(b).val();
    $(b).val(a.replace(",", "."))
};