/* HTML CSERE */
function changeHtml(i, h, fi, fo) {
    if (fi == undefined) {
        var fi = "fast"
    }
    if (fo == undefined) {
        var fo = "fast"
    }
    try {
        $("#" + i).fadeOut(fo, function () {
            $("#" + i).html(h).fadeIn(fi)
        })
    } catch (err) {
    }
};